.body-container {
	position: relative;
	width: 100%;
	height: 100vh;
	top: 100vh;
	background: white;
	overflow: hidden;
	transition: all 0.5s ease-in-out;
	margin-bottom: 100vh;
}

h1 {
	color: var(--custom-black);
	font-family: "New Eddy Display";
	font-size: 5em;
	padding: 0 0 20px 0;
	transition: all 0.5s ease;
}

h2 {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	color: var(--custom-black);
	font-family: cursive;
	transition: all 0.5s ease;
	font-size: 2.5em;
}

.little-box {
	font-family: cursive;
}

.text {
	height: 100%;
	display: flex;
	margin: 0 auto;
	justify-content: center;
	position: relative;
	padding: 10px;
	flex-direction: column;
	flex-shrink: 1;
	opacity: 0.75;
	width: 100%;
	transition: all 0.5s ease;
	overflow: hidden;
}

.text h2 {
	display: flex;
	position: relative;
	flex-direction: column;
	flex-shrink: 1;
	align-items: flex-start;
	transition: all 0.5s ease;
}

.main-section {
	border-top: 1px solid var(--sandybrown);
	padding: 20px;
	height: 100%;
	border-bottom: 1px solid var(--sandybrown);
	transition: all 0.5s ease-in;
}

.big-box {
	display: block;
	align-items: center;
	justify-content: space-around;
	margin: 0 auto;
	padding: 0;
	transition: all 0.5s ease;
}

.big-box h1 {
	display: block;
	letter-spacing: 0.2rem;
	transition: all 0.5s ease-out;
	font-size: 3vw;
	font-family: "Times New Roman", Times, serif;
	text-transform: uppercase;
	position: relative;
}

@media screen and (max-width: 600px) {
	.big-box h1 {
		font-size: 8vw;
	}
}

/* Projects */
#projects {
	position: relative;
	width: 80%;
	color: var(--black);
	transition: all 0.5s ease-in;
	height: 100%;
	padding-bottom: 20px;
	margin: 0 auto;
}
.projects-block {
	padding: 10px;
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-content: center;
	align-items: center;
	transition: all 0.3s ease-out;
}

.proj-image {
	display: flex;
	left: 0;
	margin: 0 auto;
	grid-template: 50%;
	padding: 10px;
	transition: all 0.3s ease-out;
}
.proj-contents {
	right: 0;
	display: block;
	position: relative;
	margin: 0 auto;
	padding: 10px;
	grid-template: 50%;
	transition: all 0.3s ease-out;
}

.projects-block .image {
	position: relative;
	width: 350px;
	height: 350px;
	object-fit: cover;
	display: flex;
	transition: all 0.3s ease-out;
}

.projects-block span {
	display: flex;
	margin: 10px 0;
	position: relative;
	font-size: 22px;
	color: var(--black);
	letter-spacing: 0.1em;
	font-weight: 500;
	text-transform: uppercase;
	transition: all 0.5s ease-in;
	align-items: center;
	justify-content: center;
}
.projects-block .proj-name {
	padding: 10px;
	box-shadow: 1px 1px var(--sandybrown);
}

.projects-block small {
	font-family: "Courier New", Courier, monospace;
	font-weight: 600;
	text-transform: uppercase;
}

.projects-block small span {
	letter-spacing: 0.5px;
	position: relative;
	text-transform: uppercase;
	display: block;
	margin-bottom: 5vh;
	font-family: "Courier New", Courier, monospace;
	font-size: 12px;
}
.projects-block .desc span {
	font-family: Georgia, "Times New Roman", Times, serif;
	padding: 10px;
	align-items: center;
	justify-content: center;
	display: flex;
	font-size: 16px;
}

.projects-block p {
	font-family: monospace;
	display: block;
	letter-spacing: 0.5px;
	margin-bottom: 5vh;
	padding: 10px;
}

.projects-block a {
	display: block;
	text-decoration: none;
	color: var(--darkslategray);
	padding: 10px;
	left: 0;
	cursor: pointer;
	text-transform: uppercase;
	margin: 0;
	letter-spacing: 1px;
}

.projects-block .projects-slider .project-swiper-pagination {
	margin-top: 30px;
	position: relative;
}

.projects-slider .project-swiper-pagination .project-swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	background-color: var(--white);
	opacity: 1;
	border: 1px solid var(--burlywood);
	transition: all 0.5s ease-in;
}

.projects-slider
	.project-swiper-pagination
	.project-swiper-pagination-bullet-active {
	background-color: var(--burlywood);
	transition: all 0.5s ease-in;
}

.source {
	border: 1px solid var(--black);
	background: var(--white);
	transition: all 0.5s ease-in;
}
.source a {
	color: var(--black);
	transition: all 0.5s ease-in;
}

.source:hover a {
	background: var(--darkslategray);
	color: var(--white);
	border: none;
	transition: all 0.5s ease-in;
}

@media screen and (max-width: 875px) {
	.projects-block .image {
		width: 250px;
		height: 250px;
	}
}

@media screen and (max-width: 650px) {
	.projects-block {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 400px) {
	.projects-block .image {
		width: 100%;
		height: 100%;
	}
}

@media screen and (max-width: 310px) {
	.projects-block .image {
		width: 100%;
		height: 100%;
	}
	.projects-block span {
		font-size: 10vw;
	}

	.proj-contents {
		display: flex;
		width: 100%;
		flex-direction: column;
		flex-shrink: 1;
	}
	.proj-image {
		width: 100%;
	}
	.projects-block small {
		font-size: 5vw;
		display: inline-flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-content: center;
		justify-content: center;
		align-items: center;
	}
	.projects-block .proj-name {
		font-size: 5vw;
		padding: 10px;
		display: flex;
		box-shadow: none;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: center;
		justify-content: center;
		align-items: center;
	}
	.projects-block .desc span,
	.proj-contents small span,
	.desc span,
	.desc,
	.projects-block a {
		font-size: 5vw;
	}
	.projects-block a {
		padding: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
