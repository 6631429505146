:root {
	--black: #000;
	--white: #fff;
	--custom-black: #333;
	--burlywood: burlywood;
	--sandybrown: sandybrown;
	--black-color: #000;
	--custom-black: #111;
	--cyan: cyan;
	--dogderblue: dodgerblue;
	--darkslategray: darkslategray;
}

.dark {
	--black-color: #fff;
	--black: #fff;
	--white: #000;
	--custom-black: #fff;
	--burlywood: burlywood;
	--sandybrown: sandybrown;
	--dogderblue: #fff;
	--darkslategray: green;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	scroll-snap-type: y mandatory;
	background: white;
}

.legacy {
	display: flex;
	opacity: 0.3;
	align-items: flex-end;
	justify-content: flex-end;
	padding: 10px;
	width: 100%;
}

.legacy h1 {
	right: 0;
	font-family: serif;
	font-weight: 500;
	position: relative;
}
.legacy p {
	position: absolute;
	letter-spacing: 0.1rem;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 500;
	transform: rotate(270deg);
	left: 0;
	top: 10vh;
}
.legacy h4 {
	position: absolute;
	right: 5vh;
	top: 5vh;
}
