:root {
	--black-color: #000;
	--custom-black: #111;
	--sandybrown: sandybrown;
	--burlywood: burlywood;
	--white: #fff;
	--cyan: cyan;
	--dogderblue: dodgerblue;
}

.header {
	float: right;
	left: 0;
	top: 100vh;
	height: 100%;
	position: absolute;
	background: var(--white);
	opacity: 0;
	width: 100%;
	transition: all 0.3s ease-out;
	z-index: 999999;
	color: var(--black-color);
}
.nav {
	display: flex;
	padding: 20px;
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: space-between;
	z-index: 999;
	background: var(--white);
}

.nav .links {
	float: right;
}

.links ul {
	display: flex;
	list-style: none;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 0 auto;
	width: 100%;
	top: 0;
	transition: all 0.3s ease-in-out;
	right: 0;
}

.links a {
	font-family: "Courier New", Courier, monospace;
	font-size: small;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: var(--black-color);
	margin-left: 4.5rem;
	display: inline-block;
	transition: all 0.3s ease-in;
	align-items: center;
	justify-content: center;
	padding: 5px;
	transition: all 0.3s ease-in-out;
}

.links a:hover {
	transition: all 0.3s ease-in;
	color: var(--dogderblue);
}

.logo {
	float: left;
}

.logo a {
	top: 0;
	font-family: monospace;
	color: var(--black-color);
	text-decoration: none;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 600;
	font-size: medium;
	border-bottom: 1px solid var(--black-color);
}

.hamburger-menu {
	position: absolute;
	width: 2rem;
	height: 2rem;
	z-index: 99;
	display: none;
	align-items: center;
	cursor: pointer;
	justify-content: center;
	transform: translateY(-50%, -50%);
	right: 10px;
}

.hamburger-menu .bar {
	position: relative;
	width: 100%;
	height: 3px;
	background-color: var(--black-color);
	border-radius: 3px;
	transition: 0.5s;
}

.bar::before,
.bar::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: var(--black-color);
	border-radius: 3px;
	transition: 0.5s;
}

.bar::before {
	transform: translateY(-8px);
}

.bar::after {
	transform: translateY(8px);
}

.big-wrapper.active .hamburger-menu .bar {
	background-color: transparent;
}

.big-wrapper.active .bar::before {
	transform: translateY(0) rotate(-45deg);
}

.big-wrapper.active .bar::after {
	transform: translateY(0) rotate(45deg);
}

.header h1 {
	top: 10vh;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-between;
	margin: 10px 20px;
	font-family: serif;
	letter-spacing: 1px;
	transition: all 0.5s ease-in;
	font-size: xx-large;
	padding: 10px;
	border: 1px solid var(--white);
	text-transform: uppercase;
}

.header h2 {
	right: 0;
	font-size: large;
	font-family: Georgia, "Times New Roman", Times, serif;
	letter-spacing: 1.5px;
	transition: all 0.3s ease-in-out;
	transform: rotate(-270deg);
	top: 50vh;
	position: absolute;
	display: flex;
	padding: 0;
	margin-right: 0;
	transition: all 0.5s ease;
	justify-content: center;
}

@media screen and (max-width: 900px) {
	.header h2 {
		right: -5vh;
		transition: all 0.5s ease;
	}
}

@media screen and (max-width: 700px) {
	.header h2 {
		right: -10vh;
		transition: all 0.5s ease;
	}
}

@media screen and (max-width: 200px) {
	.logo a {
		position: absolute;
		display: block;
		border: none;
		align-items: center;
		justify-content: center;
		left: 0;
		padding: 5px;
	}
}

@media screen and (max-width: 655px) {
	.body-container {
		top: 100vh;
	}

	.header h1 {
		top: 10vh;
	}
	h1 {
		display: flex;
		flex-direction: column;
		flex-shrink: 1;
		padding: 0;
	}
}

@media screen and (max-width: 730px) {
	.hamburger-menu {
		display: flex;
		transition: all 0.3s ease-in;
	}

	.links {
		color: var(--white);
		position: fixed;
		top: 0;
		right: 0;
		max-width: 450px;
		width: 100%;
		height: 100%;
		background-color: var(--black-color);
		z-index: 95;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: translateX(100%);
		transition: all 0.5s ease-in-out;
	}

	.links ul {
		flex-direction: column;
	}

	.links a {
		color: #fff;
		margin-left: 0;
		padding: 2rem 0;
	}

	.header.active .links {
		transform: translateX(0);
		box-shadow: 0 0 50px 2px rgba(0, 0, 0, 0.4);
		transition: all 0.5s ease-in-out;
	}
	.header.active .hamburger-menu .bar {
		transition: all 0.3s ease-in-out;
		background: var(--white);
	}
}

@media screen and (max-width: 415px) {
	.header h2 {
		transition: all 0.5s ease-in;
		transform: rotate(0deg);
		position: relative;
		top: 10vh;
		left: 0;
		font-weight: 400;
		padding: 10px;
	}
}
