* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	top: 0;
}

body::-webkit-scrollbar {
	display: none;
}

a {
	text-decoration: none;
}

li {
	list-style-type: none;
}

#root {
	scroll-behavior: smooth;
	scroll-snap-type: y mandatory;
	width: 100%;
	height: 100%;
}
