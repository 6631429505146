.stack {
	top: 20%;
	display: flex;
	width: 70%;
	height: 50%;
	align-items: center;
	justify-content: space-between;
	position: relative;
	margin: 0 auto;
	transition: all 0.5s ease-in;
}

.section-title {
	margin-bottom: 70px;
}

.section-title h2 {
	font-size: 25px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

#services {
	position: relative;
	padding: 20px;
	width: 100%;
	color: var(--black);
	transition: all 0.5s ease-in;
}

.services-block span {
	margin: 10px 0;
	position: relative;
	font-size: 13px;
	color: var(--black);
	letter-spacing: 0.1em;
	font-weight: 500;
	text-transform: uppercase;
	display: block;
	transition: all 0.5s ease-in;
}

.services-block .separator {
	margin: 0;
	font-size: 13px;
	line-height: 22px;
}

.services-block i {
	font-size: 44px;
	color: var(--sandybrown);
	line-height: 44px;
}

.services-slider .swiper-pagination {
	margin-top: 30px;
	position: relative;
}

.services-slider .swiper-pagination .swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	background-color: var(--white);
	opacity: 1;
	border: 1px solid var(--burlywood);
	transition: all 0.5s ease-in;
}

.services-slider .swiper-pagination .swiper-pagination-bullet-active {
	background-color: var(--burlywood);
	transition: all 0.5s ease-in;
}

@media screen and (max-width: 500px) {
	.stack {
		top: 10vh;
	}
}
