.footer {
	width: 100%;
	height: 250px;
	background: var(--white);
	color: var(--black);
	left: 0;
	bottom: 0;
	z-index: 9999;
	align-items: center;
	justify-content: center;
	position: relative;
}

.col {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.col li {
	display: block;
	list-style: none;
	font-family: "Monument Extended";
	text-transform: uppercase;
	font-size: 0.8em;
	letter-spacing: 1px;
	line-height: 30px;
}
.col li a {
	text-decoration: none;
	color: var(--black);
}

.col li a:hover {
	border: 0.5px solid saddlebrown;
	padding: 5px;
	transition: all 0.5s ease;
	letter-spacing: 2px;
}

.col p {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1em;
}
.col span {
	display: flex;
	align-items: center;
	justify-content: center;
}

.col li:first-child {
	font-weight: bolder;
}

.signature {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5vh;
	padding: 10px;
}

@media screen and (max-width: 310px) {
	.col li:last-child {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		flex-shrink: 1;
		padding: 5px;
		margin: 0 auto;
	}
}
