:root {
	--black: #000;
	--white: #fff;
	--custom-black: #333;
	--burlywood: burlywood;
	--sandybrown: sandybrown;
}

.awe-section {
	scroll-snap-align: start;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	position: fixed;
	display: flex;
	background: var(--white);
	color: var(--black);
	margin-bottom: 100vh;
	align-items: center;
	justify-content: center;
	font-family: "Courier New", Courier, monospace;
	transition: all 0.5s ease;
	padding: 0;
}

.awe-section h1 {
	color: var(--custom-black);
	font-family: "Times New Roman", Times, serif;
	font-size: 5rem;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: all 0.5s ease-in;
}

.awe-section h1 span {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 7rem;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: var(--burlywood);
	padding: 20px;
	border: 1px solid var(--sandybrown);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.4s ease;
}

.awe-section small {
	display: flex;
	font-family: "Courier New", Courier, monospace;
	font-size: 1.5rem;
	font-weight: 400;
	text-transform: uppercase;
	margin-top: 10px;
	transition: all 0.5s ease-out;
}

.typed {
	font-family: serif;
	letter-spacing: 1px;
	font-size: 2rem;
	font-weight: 500;
	transition: all 0.5s ease-in-out;
}

.scrolldown-container {
	position: absolute;
	bottom: 10%;
	left: 50%;
	width: 20px;
	height: 20px;
	transition: all 0.5s ease;
	transform: translateY(0) translateY(-50%) rotate(45deg);
}

.scrolldown-container span {
	border-radius: 5px;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	border-bottom: 2px solid var(--black);
	border-right: 2px solid var(--black);
	animation: animate 1.5s linear infinite;
	opacity: 0;
	transition: all 0.5s ease;
}

.scrolldown-container span:nth-child(1) {
	transform: translate(-10px, -10px);
	animation-delay: -0.5s;
}
.scrolldown-container span:nth-child(2) {
	transform: translate(0, 0);
	animation-delay: -0.4s;
}
.scrolldown-container span:nth-child(3) {
	transform: translate(10px, 10px);
	animation-delay: 0s;
}

@keyframes animate {
	0% {
		top: -5px;
		left: -5px;
		opacity: 0;
	}
	25% {
		top: 0px;
		left: 0px;
		opacity: 1;
	}
	100% {
		top: 5px;
		left: 5px;
		opacity: 0;
	}
}

@media screen and (max-width: 650px) {
	.awe-section {
		width: 100%;
		height: 100%;
		padding: 10px;
	}
	.awe-section h1 {
		font-size: 4rem;
		transition: all 0.4s ease-in;
	}
	.awe-section h1 span {
		padding: 0;
		font-size: 2.5rem;
		padding: 20px 0px 20px 0px;
		transition: all 0.4s ease;
	}
	.awe-section small {
		font-size: 1rem;
		transition: all 0.5s ease-in;
	}
	.typed {
		margin-top: 5vh;
		font-size: 1.5rem;
		font-weight: 400;
		transition: all 0.5s ease-in-out;
	}
}

@media screen and (max-width: 530px) {
	.awe-section h1 {
		font-size: 3rem;
		transition: all 0.4s ease-in;
	}
	.awe-section h1 span {
		padding: 10px 0px 10px 0px;
		transition: all 0.4s ease-in;
	}
	.awe-section small {
		font-size: 0.75rem;
		transition: all 0.5s ease-in;
	}
	.typed {
		margin-top: 5vh;
		font-size: 1rem;
		transition: all 0.5s ease-in-out;
	}
}

@media screen and (max-width: 400px) {
	.awe-section h1 {
		font-size: 2rem;
		transition: all 0.4s ease-in;
	}
	.typed {
		margin-top: 5vh;
		font-size: 0.75rem;
		transition: all 0.5s ease-in-out;
	}
}

@media screen and (max-width: 350px) {
	.awe-section h1 {
		font-size: 1.5rem;
		transition: all 0.4s ease-in;
	}
	.awe-section h1 span {
		font-size: 1rem;
		padding: 5px 0px 5px 0px;
		transition: all 0.4s ease-in;
	}
	.awe-section small {
		font-size: 0.5rem;
		transition: all 0.5s ease-in;
	}
	.typed {
		margin-top: 5vh;
		font-size: 1rem;
		transition: all 0.5s ease-in-out;
	}
}

@media screen and (max-width: 310px) {
	.header h1,
	.services-block span {
		font-size: 7vw;
		font-weight: 600;
	}
	.awe-section h1 {
		font-size: 5vw;
		display: flex;
		align-items: center;
	}
	.awe-section small {
		font-size: 2.5vw;
	}
	.typed {
		font-size: 2.5vw;
	}
}
